<template>
  <div class="admin-deep">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
    <admin-account-table ref="queryData" @tabButtonClick="tabButtonClick" :newsQueryData="newsQueryData"></admin-account-table>
    <dialog-box ref="queryDialog" :dialogShow="dialogShow" :tabData="tabData" @handleClose='handleClose' @jurisdiction='jurisdiction' title="分配角色" componentName="RoleBody" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' }, {label:'确认',action:'action',size:'mini',type:'primary'}]" @action="action"></dialog-box>
    <!-- 添加管理员 -->
    <el-dialog title="新增账号" v-model="dialogRole" width="30%">
      <div class="role-top">
        手机号、邮箱、用户名均可作为登录账号使用，请仔细核对手机号和邮箱，避免为其他无关人员授权。
      </div>
      <div class="role-cont">
        <el-form size="mini" :model="jobNumber" ref="jobNumber" :rules="newsRules" label-width="110px" class="demo-ruleForm">
          <el-form-item label="员工 ITcode:" prop="jobNum">
            <el-input type="jobNum" v-model="jobNumber.jobNum" placeholder="请输入员工ITcode" @blur="itCodeBlur"></el-input>
            <el-button class="subJob" icon="el-icon-search" @click="submitFormJob('jobNumber')"></el-button>
          </el-form-item>
          <el-form-item label="密码:" prop="password">
              <el-input type="password" v-model="jobNumber.password" placeholder="请输入密码" show-password></el-input>
          </el-form-item>
          <div class="job-serch">
            <div class="job-name clearfix">
              <div class="job-left">公司内部名:</div>
              <div class="job-right">{{ jobNumber.internalName }}</div>
            </div>
            <div class="job-name clearfix">
              <div class="job-left">部门:</div>
              <div class="job-right">{{ jobNumber.department }}</div>
            </div>
            <div class="job-name clearfix">
              <div class="job-left">手机号:</div>
              <div class="job-right">{{ jobNumber.phone }}</div>
            </div>
            <div class="job-name clearfix">
              <div class="job-left">邮箱:</div>
              <div class="job-right">{{ jobNumber.mail }}</div>
            </div>
          </div>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="mini" @click="clockAssignRoles">取消</el-button>
          <el-button size="mini" type="primary" @click="assignRoles">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { requestForm, request } from '@/assets/js/http.js'
import ListSearch from '@/components/actionList/ListSearch.vue'
import ListMenu from '@/components/actionList/ListMenu.vue'
import AdminAccountTable from './AdminAccountTable.vue'
import DialogBox from '@/components/plug/DialogBox.vue'
import { AdminAccountSearchList, AdminAccountSearchData } from './js/AdminAccount.js'
// import Component from '@/assets/js/components.js'
export default {
  // components: Component.components,
  components: { ListSearch, AdminAccountTable, ListMenu, DialogBox },
  name: 'AdminAccount',
  data: function () {
    var checkChinese = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入员工ITcode'))
      } else {
        if (value !== '') {
          var reg = /[\u4E00-\u9FA5]/g
          if (reg.test(value)) {
            callback(new Error('不能输入中文'))
          }
        }
        callback()
      }
    }
    return {
      dialogShow: false,
      dialogRole: false,
      tabData: '',
      roleData: '',
      newCheckedCities: '',
      newsQueryData: '',
      childData: { searchList: AdminAccountSearchList, searchData: AdminAccountSearchData },
      menudata: this.$store.state.ViewMenuData.buttonData,
      jobNumber: {
        jobNum: '',
        internalName: '',
        department: '',
        phone: '',
        mail: '',
        password: ''
      },
      newsRules: {
        jobNum: [
          { required: true, validator: checkChinese, trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getJsData()
  },
  mounted () {
    this.jurisdiction()
    // 绑定enter事件
    window.addEventListener('keyup', this.itCodeEnter)
  },
  onUnmounted () {
    // 销毁enter事件
    window.removeEventListener('keyup', this.itCodeEnter)
  },
  methods: {
    // 获取角色下拉框中的数据
    getJsData () {
      const obj = {
        pageNum: 1,
        pageSize: 10000
      }
      requestForm('/api/system/role/list', 'POST', obj).then((res) => {
        if (res.code === '200') {
          var newOption = [{ label: '全部', value: '' }]
          res.data.records.forEach((item, index) => {
            newOption.push({
              label: item.roleDesc,
              value: item.roleCode
            })
          })
          this.childData.searchList[4].option = newOption
        }
      })
    },
    // 点击分配角色弹框及提交选中权限
    action () {
      if (this.newCheckedCities) {
        var uID = this.tabData.id
        var rID = this.newCheckedCities
        var newRid = []
        rID.forEach((item, index) => {
          newRid.push(item.id)
        })
        var obj = []
        newRid.forEach((item, index) => {
          obj.push({ userId: uID, roleId: item })
        })
        request('/api/system/user/saveUserRole', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.$refs.queryData.GetTableData()
            this.dialogShow = false
          }
        })
      } else {
        this.$message({
          showClose: true,
          message: '请选择角色',
          type: 'error'
        })
      }
    },
    // 接收子组件-分配权限数据
    jurisdiction (data) {
      this.newCheckedCities = data
    },
    // 关闭弹框
    handleClose () {
      this.dialogShow = false
    },
    // 获取当前某一条分配角色的数据
    tabButtonClick (data) {
      this.tabData = data
    },
    // 添加管理员弹框
    buttonClick: function (data) {
      this.dialogRole = true
    },
    // 根据员工ITcode查询
    submitFormJob (jobNumber) {
      this.$refs[jobNumber].validate((valid) => {
        if (valid) {
          request('/api/sap/getEmployeeUsername/' + this.jobNumber.jobNum, 'GET').then((res) => {
            if (res !== '' && res !== null) {
              this.jobNumber.internalName = res.ename
              this.jobNumber.department = res.zpipt
              this.jobNumber.phone = res.zzsrsj
              this.jobNumber.mail = res.zzgsyx
            } else {
              this.$message({
                showClose: true,
                message: '该用户不存在',
                type: 'error'
              })
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 失去焦点查询员工ITcode数据
    itCodeBlur () {
      this.submitFormJob('jobNumber')
    },
    // 回车查询员工ITcode数据
    itCodeEnter () {
      const code = event.keyCode
      if (code === 13) {
        this.submitFormJob('jobNumber')
      }
    },
    // 获取添加管理员数据
    assignRoles () {
      if (this.jobNumber.internalName && this.jobNumber.password) {
        const obj = {
          username: this.jobNumber.jobNum,
          name: this.jobNumber.internalName,
          phone: this.jobNumber.phone,
          mail: this.jobNumber.mail,
          deptName: this.jobNumber.department,
          password: this.jobNumber.password
        }
        request('/api/system/user/save', 'post', obj).then((res) => {
          if (res.code === '200') {
            this.$refs.queryData.GetTableData()
            this.dialogRole = false
            this.$message({
              showClose: true,
              message: '管理员添加成功',
              type: 'success'
            })
          }
        })
      } else {
        this.$message({
          showClose: true,
          message: '请输入ITcode搜索并设置密码',
          type: 'error'
        })
        return false
      }
    },
    // 取消
    clockAssignRoles () {
      this.dialogRole = false
    },
    // 查询
    onSearch (data) {
      this.newsQueryData = this.childData.searchData
      this.$refs.queryData.GetTableData(data)
    },
    // 清空
    clearSearch (data) {
      this.newsQueryData = this.childData.searchData
      this.$refs.queryData.GetTableData()
    }
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    },
    newCheckedCities: function (val) {

    }
  }
}
</script>

<style scoped lang="scss">
  .admin-deep{
    :deep(.el-overlay){
      justify-content: center;
      align-items: center;
      display: flex;
    }
    :deep(.el-dialog__body){
      padding: 0;
    }
    :deep(.el-dialog__header){
      padding: 15px 20px 15px;
      border-bottom: 1px solid #e4e4e4;
    }
    :deep(.el-dialog__footer){
      padding: 15px 20px;
      border: 1px solid #e4e4e4;
    }
    :deep(.el-dialog){
      width: 500px !important;
      margin: 0 !important;
    }
    :deep(.el-checkbox){
      margin-bottom: 20px;
    }
    :deep(.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before), :deep(.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before){
      display: none;
    }
  }
  .role-top{
    background-color: rgba(68, 135, 250, 0.2);
    margin-top: 10px;
    padding: 10px 20px;
  }
  .role-cont{
    padding: 20px 70px;
  }
  .subJob{
    position: absolute;
    right: 1px;
    top: 1px;
    border: none;
    background: none;
  }
  .job-left{
    float: left;
    width: 98px;
    margin-right: 12px;
    text-align: right;
  }
  .job-right{
    float: left;
  }
  .job-name{
    margin-bottom: 15px;
  }
</style>
